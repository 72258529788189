import React from "react";

const Gajeta = () => {
  return (
    <div>
      <section className="flex flex-col md:flex-row items-center mt-20 relative bg-black">
        <div className="w-full md:w-1/2 flex flex-col mb-2 md:mb-0 md:ml-10">
          <img
            src="/gajeta.png"
            alt="Gajeta - Traditional Wooden Boat"
            className="w-full md:w-[500px] h-[500px] mx-auto md:mx-0"
          />
        </div>
        <div className="">
          <div className="absolute bottom-10 left-[40%] mt-">
            <img
              src="/muzej-logo.svg"
              alt="Muzej Logo"
              width={100}
              height={100}
              className="sm:mt-20 mb-0 hidden lg:block"
            />
          </div>
        </div>
        <div
          className="w-full md:w-1/2 px-8 md:mr-10 mt-2 md:mt-0 mb-10 lg:mb-4"
          style={{ textAlign: "justify" }}
        >
          <h1 className="text-2xl mb-8 text-center md:text-left">GAJETA</h1>
          <p className="text-xl">
            The betin gajeta is a wooden boat between 5 and 8 meters long, 2 to
            2.60 meters wide, without a deck along its entire length. It is more
            robust in form and construction than Komi falkuša or Korčula gajeta.
            The Bettina shipbuilders managed to innovate on the gajeta,
            originally a fishing boat, to achieve the proportions and form of a
            ship that was perfectly adapted to the life needs and rural economy
            of the inhabitants of the island of Murter.
          </p>
          <p className="text-xl mt-2">
            The betin gajeta is a wooden boat between 5 and 8 meters long, 2 to
            2.60 meters wide, without a deck along its entire length. It is more
            robust in form and construction than Komi falkuša or Korčula gajeta.
            The Bettina shipbuilders managed to innovate on the gajeta,
            originally a fishing boat, to achieve the proportions and form of a
            ship that was perfectly adapted to the life needs and rural economy
            of the inhabitants of the island of Murter.
          </p>
          <p className="text-xl mt-2">
            Betina shipbuilding differs from other shipbuilding typologically
            the same or similar ships, and has its own recognizable
            specificities.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Gajeta;

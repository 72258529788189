import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainPage from "./components/MainPage";

import WoodenBoats from "./pages/gallery/WoodenBoats";
import FishImages from "./pages/gallery/Fish";
import OtherImages from "./pages/gallery/Other";
import ImagesAll from "./pages/gallery/All";

import ContactPage from "./pages/contact/ContactPage";
import BlogPage from "./pages/blog/BlogPage";

function App() {
  return (
    <div className="w-full h-screen bg-black text-white">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/gallery/wooden-boats" element={<WoodenBoats />} />
          <Route path="/gallery/fish" element={<FishImages />} />
          <Route path="/gallery/other" element={<OtherImages />} />
          <Route path="/gallery/all" element={<ImagesAll />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import Close from "@mui/icons-material/Close";
import Button from "@mui/joy/Button";

export default function CarouselThree() {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);

  const imageInfoArray = [
    {
      src: "/slika7.webp",
      date: "July 2022",
      name: "đir po moru",
      width: 470,
      height: 210,
      xl: { width: 700, height: 400 },
    },
    {
      src: "/slika8.webp",
      date: "April 2023",
      name: "falkuša",
      width: 550,
      height: 200,
      xl: { width: 920, height: 500 },
    },
    {
      src: "/slika9.webp",
      date: "September 2022",
      name: "zalazak sunca",
      width: 470,
      height: 200,
      xl: { width: 670, height: 400 },
    },
  ];

  const openFullscreen = (src) => {
    setFullscreenImage(src);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative lg:fixed">
      <Helmet>
        <title>Edo Opanovic All Gallery</title>
        <meta name="description" content="Edo Opanovic Gallery of all images" />
        <meta
          name="keywords"
          content="Edo Opanovic, gallery, images, fish, boats, art, painting, Croatia, Biograd na Moru"
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <img
        src="/podloga1.webp"
        alt="Podloga"
        className="object-contain w-full lg:block hidden"
        height={720}
        width={1920}
      />
      <div className="flex flex-col items-center justify-between absolute top-0 left-0 right-0 mx-20 gap-x-8 lg:flex-row overflow-y-hidden">
        {imageInfoArray.map((imageInfo, index) => (
          <div
            key={index}
            className={`relative ${
              index === 0 || index === imageInfoArray.length - 1
                ? "py-11 2xl:py-16"
                : "pl-0"
            }`}
          >
            <img
              src={imageInfo.src}
              alt={`Slika ${index + 1}`}
              width={
                windowWidth > 1536 && imageInfo.xl
                  ? imageInfo.xl.width
                  : imageInfo.width
              }
              height={
                windowWidth > 1536 && imageInfo.xl
                  ? imageInfo.xl.height
                  : imageInfo.height
              }
              className={
                "object-cover cursor-pointer drop-shadow-2xl shadow-black"
              }
              onClick={() => openFullscreen(imageInfo.src)}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        ))}
      </div>

      {fullscreenImage && (
        <div
          className="fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-100 flex items-center justify-center p-4"
          onClick={closeFullscreen}
        >
          <div className="relative flex flex-col items-center justify-center w-full h-full text-white lg:flex lg:flex-row">
            <div className="relative w-full lg:w-3/4 h-full max-w-[100vw] max-h-[100vh]">
              <img
                src={fullscreenImage}
                alt="Fullscreen"
                className="object-contain w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className="relative w-full lg:w-1/4 h-full p-4 flex flex-col items-center justify-center">
              {imageInfoArray.map((imageInfo, index) => {
                if (imageInfo.src === fullscreenImage) {
                  return (
                    <div key={index}>
                      <div className="absolute top-0 right-0 mt-4 mr-4 hidden lg:block">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={closeFullscreen}
                        >
                          <Close className="w-5 h-5" />
                        </Button>
                      </div>
                      <div className="mx-0 lg:mx-0 w-full 2xl:w-96 font-light">
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Name: <strong>{imageInfo.name}</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Date: <strong>{imageInfo.date}</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Price: <strong>on request</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Mail:{" "}
                          <strong>
                            <a href="mailto:info@dalmatinske-vizure.com">
                              info@dalmatinske-vizure.com
                            </a>
                          </strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Mail:{" "}
                          <strong>
                            <a href="mailto:info@dalmatinske-vizure.com">
                              info@fine-art-of-dalmatia.com
                            </a>
                          </strong>
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import Button from "@mui/joy/Button";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";

import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/joy/IconButton";

export default function MainHero() {
  const [showGalleryOptions, setShowGalleryOptions] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleMouseEnter = () => {
    setShowGalleryOptions(true);
  };

  const handleMouseLeave = () => {
    setShowGalleryOptions(false);
  };

  const handleOptionClick = () => {
    setShowGalleryOptions(false);
  };

  return (
    <div className="relative font-extralight lg:fixed lg:w-screen text-black">
      <Helmet>
        <title>Edo Opanovic</title>
        <meta name="description" content="Edo Opanovic Fine Art Digital Art" />"
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="absolute top-0 left-0 ml-2 lg:ml-10 z-10 pt-2 flex flex-row items-center">
        <img
          src="/logo.png"
          alt="Edo Opanovic Logo"
          width={50}
          height={50}
          loading="eager"
        />
        <div className="ml-2 flex items-center justify-center flex-col">
          <p className="font-light text-2xl lg:text-3xl">
            Fine Art of Dalmatia
          </p>
          <p className="text-xl lg:text-2xl font-light">Digital Art</p>
        </div>
      </div>

      <div className="lg:hidden ml-auto">
        <div className="absolute top-2 right-0 mr-2 z-10 pt-2 flex items-center">
          <IconButton onClick={() => setOpen(true)}>
            <Menu
              sx={{
                color: "black",
                width: "36px",
                height: "36px",
              }}
            />
          </IconButton>
          <Drawer open={open} onClose={() => setOpen(false)} size="sm">
            <div className="md:flex flex flex-col items-center justify-center gap-4 p-0">
              <ModalClose id="close-icon" onClick={() => setOpen(false)} />
              <div className="flex items-center justify-center flex-col mt-10 gap-4 text-2xl font-light">
                <a href="/">
                  <Button size="navigation" variant="navigation">
                    Home
                  </Button>
                </a>

                <div className="relative">
                  <Button
                    size="navigation"
                    variant="navigation"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Gallery
                  </Button>

                  {showGalleryOptions && (
                    <div
                      className="flex flex-col items-start absolute top-full -left-5 z-10 bg-black p-1 max-h-[180px] rounded-sm text-white"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <a href="/gallery/wooden-boats">
                        <Button
                          size="lg"
                          variant="plain"
                          sx={{
                            height: "40px",
                            padding: "8px",
                            textWrap: "nowrap",
                            fontSize: "18px",
                            fontWeight: "300",
                            fontFamily: "inherit",
                            color: "white",
                          }}
                          onClick={handleOptionClick}
                        >
                          Wooden Boats
                        </Button>
                      </a>

                      <a href="/gallery/fish">
                        <Button
                          size="lg"
                          variant="plain"
                          sx={{
                            height: "40px",
                            padding: "8px",
                            textWrap: "nowrap",
                            fontSize: "18px",
                            fontWeight: "300",
                            fontFamily: "inherit",
                            color: "white",
                          }}
                          onClick={handleOptionClick}
                        >
                          Fish
                        </Button>
                      </a>

                      <a href="/gallery/other">
                        <Button
                          size="lg"
                          variant="plain"
                          sx={{
                            height: "40px",
                            padding: "8px",
                            textWrap: "nowrap",
                            fontSize: "18px",
                            fontWeight: "300",
                            fontFamily: "inherit",
                            color: "white",
                          }}
                          onClick={handleOptionClick}
                        >
                          Other
                        </Button>
                      </a>

                      <a href="/gallery/all">
                        <Button
                          size="lg"
                          variant="plain"
                          sx={{
                            height: "40px",
                            padding: "8px",
                            textWrap: "nowrap",
                            fontSize: "18px",
                            fontWeight: "300",
                            fontFamily: "inherit",
                            color: "white",
                          }}
                          onClick={handleOptionClick}
                        >
                          All
                        </Button>
                      </a>
                    </div>
                  )}
                </div>

                <a href="/blog">
                  <Button size="navigation" variant="navigation">
                    Blog
                  </Button>
                </a>

                <a href="/contact">
                  <Button size="navigation" variant="navigation">
                    Contact
                  </Button>
                </a>
              </div>
            </div>
          </Drawer>
        </div>
      </div>

      <nav className="lg:flex items-center justify-between absolute top-4 right-0 mr-20 mt-1 z-10 text-3xl hidden">
        <div className="flex flex-row items-center justify-center space-x-4">
          <a href="/">
            <Button
              size="lg"
              variant="plain"
              className="hover:bg-inherit"
              sx={{
                height: "2.5rem",
                paddingX: "16px",
                paddingY: "0px",
                fontSize: "30px",
                fontWeight: "300",
                fontFamily: "inherit",
                color: "black",
                ":hover": {
                  backgroundColor: "inherit",
                  color: "#F33A6A",
                  transition: "all 0.3s ease",
                },
              }}
            >
              Home
            </Button>
          </a>

          <div className="relative">
            <Button
              size="lg"
              variant="plain"
              className="hover:bg-inherit"
              sx={{
                height: "2.5rem",
                paddingX: "16px",
                paddingY: "0px",
                fontSize: "30px",
                fontWeight: "300",
                fontFamily: "inherit",
                color: "black",
                ":hover": {
                  backgroundColor: "inherit",
                  color: "#F33A6A",
                  transition: "all 0.3s ease",
                },
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Gallery
            </Button>
            {showGalleryOptions && (
              <div
                className="flex flex-col items-start absolute top-full left-0 bg-white p-1 max-h-[180px] rounded-sm"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a href="/gallery/wooden-boats">
                  <Button
                    size="lg"
                    variant="plain"
                    onClick={handleOptionClick}
                    sx={{
                      height: "40px",
                      padding: "8px",
                      fontSize: "20px",
                      textWrap: "nowrap",
                      fontFamily: "inherit",
                      fontWeight: "300",
                      color: "black",
                      ":hover": {
                        backgroundColor: "inherit",
                        color: "#F33A6A",
                        transition: "all 0.3s ease",
                      },
                    }}
                  >
                    Wooden Boats
                  </Button>
                </a>
                <a href="/gallery/fish">
                  <Button
                    size="lg"
                    variant="plain"
                    onClick={handleOptionClick}
                    sx={{
                      height: "40px",
                      padding: "8px",
                      fontSize: "20px",
                      textWrap: "nowrap",
                      fontFamily: "inherit",
                      fontWeight: "300",
                      color: "black",
                      ":hover": {
                        backgroundColor: "inherit",
                        color: "#F33A6A",
                        transition: "all 0.3s ease",
                      },
                    }}
                  >
                    Fish
                  </Button>
                </a>
                <a href="/gallery/other">
                  <Button
                    size="lg"
                    variant="plain"
                    onClick={handleOptionClick}
                    sx={{
                      height: "40px",
                      padding: "8px",
                      fontSize: "20px",
                      textWrap: "nowrap",
                      fontFamily: "inherit",
                      fontWeight: "300",
                      color: "black",
                      ":hover": {
                        backgroundColor: "inherit",
                        color: "#F33A6A",
                        transition: "all 0.3s ease",
                      },
                    }}
                  >
                    Other
                  </Button>
                </a>
                <a href="/gallery/all">
                  <Button
                    size="lg"
                    variant="plain"
                    onClick={handleOptionClick}
                    sx={{
                      height: "40px",
                      padding: "8px",
                      fontSize: "20px",
                      textWrap: "nowrap",
                      fontFamily: "inherit",
                      fontWeight: "300",
                      color: "black",
                      ":hover": {
                        backgroundColor: "inherit",
                        color: "#F33A6A",
                        transition: "all 0.3s ease",
                      },
                    }}
                  >
                    All
                  </Button>
                </a>
              </div>
            )}
          </div>

          <a href="/blog">
            <Button
              size="lg"
              variant="plain"
              className="hover:bg-inherit"
              sx={{
                height: "2.5rem",
                paddingX: "16px",
                paddingY: "0px",
                fontSize: "30px",
                fontWeight: "300",
                fontFamily: "inherit",
                color: "black",
                ":hover": {
                  backgroundColor: "inherit",
                  color: "#F33A6A",
                  transition: "all 0.3s ease",
                },
              }}
            >
              Blog
            </Button>
          </a>

          <a href="/contact">
            <Button
              size="lg"
              variant="plain"
              className="hover:bg-inherit"
              sx={{
                height: "2.5rem",
                paddingX: "16px",
                paddingY: "0px",
                fontSize: "30px",
                fontWeight: "300",
                fontFamily: "inherit",
                color: "black",
                ":hover": {
                  backgroundColor: "inherit",
                  color: "#F33A6A",
                  transition: "all 0.3s ease",
                },
              }}
            >
              Contact
            </Button>
          </a>
        </div>
      </nav>

      <img
        src="/hero.webp"
        alt="Edo Opanovic Fine Art Digital Art"
        width={1600}
        height={900}
        className="w-full h-screen sm:h-[800px] object-cover object-left-top lg:h-screen lg:w-screen"
      />
    </div>
  );
}

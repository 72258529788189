import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Transition } from "@headlessui/react";

import MainHero from "./Hero";
import BestWork from "./bestWork";

export default function CarouselMain() {
  const [activeCarousel, setActiveCarousel] = useState("hero");

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCarousel((prevActive) =>
        prevActive === "hero" ? "bestWork" : "hero"
      );
    }, 10000); // Promenite broj u milisekundama prema potrebi (ovde svakih 5 sekundi)

    return () => clearInterval(interval);
  }, []); //Prazno polje kao zavisnost osigurava da se useEffect izvršava samo prilikom montiranja komponente

  return (
    <div className="relative">
      <Helmet>
        <title>Edo Opanovic - Croatian Artist</title>
        <meta
          name="description"
          content="Explore the art of Edo Opanovic, a talented painter from Croatia. Discover Edo's unique style and vision in his captivating artworks."
        />
        <meta
          name="keywords"
          content="Edo Opanovic, artist, painter, Croatia, Biograd na Moru, art, painting, gallery"
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Edo Opanovic - Croatian Artist" />
        <meta
          property="og:description"
          content="Explore the art of Edo Opanovic, a talented painter from Croatia. Discover Edo's unique style and vision in his captivating artworks."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.edoopanovic.com/" />
        <meta property="og:site_name" content="Dalmatinske Vizure" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="twitter:description"
          content="Explore the art of Edo Opanovic, a talented painter from Croatia. Discover Edo's unique style and vision in his captivating artworks."
        />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />

        <link rel="canonical" href="https://www.fine-art-of-dalmatia.com/" />
      </Helmet>
      <div className="w-full h-full">
        <Transition
          show={activeCarousel === "hero"}
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <MainHero />
        </Transition>
        <Transition
          show={activeCarousel === "bestWork"}
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <BestWork />
        </Transition>
      </div>
    </div>
  );
}

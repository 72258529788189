import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Navbar } from "../../components/Navbar";

import CarouselOne from "./components/boatsOne";
import CarouselTwo from "./components/boatsTwo";
import CarouselThree from "./components/boatsThree";
import CarouselFour from "./components/boatsFour";
import CarouselFive from "./components/boatsFive";
import CarouselSix from "./components/boatsSix";

import FishCarouselOne from "./components/fishOne";

import OtherCarouselOne from "./components/otherOne";
import OtherCarouselTwo from "./components/otherTwo";

const ImagesAll = () => {
  const [activeCarousel, setActiveCarousel] = useState("carouselOne");
  const showArrowLeft = Boolean(activeCarousel !== "carouselOne");
  const showArrowRight = Boolean(activeCarousel !== "otherCarouselTwo");

  const handleCarouselChange = (direction) => {
    setActiveCarousel((prevActive) => {
      const carouselMap = {
        carouselOne: direction === "right" ? "carouselTwo" : "carouselOne",
        carouselTwo: direction === "left" ? "carouselOne" : "carouselThree",
        carouselThree: direction === "left" ? "carouselTwo" : "carouselFour",
        carouselFour: direction === "left" ? "carouselThree" : "carouselFive",
        carouselFive: direction === "left" ? "carouselFour" : "carouselSix",
        carouselSix: direction === "left" ? "carouselFive" : "fishCarouselOne",
        fishCarouselOne:
          direction === "left" ? "carouselSix" : "otherCarouselOne",
        otherCarouselOne:
          direction === "left" ? "fishCarouselOne" : "otherCarouselTwo",
        otherCarouselTwo:
          direction === "left" ? "otherCarouselOne" : "carouselOne",
      };
      return carouselMap[prevActive];
    });
  };

  return (
    <div className="m-0 w-full">
      <Helmet>
        <title>Edo Opanovic All Gallery</title>
      </Helmet>
      <Navbar />
      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565]">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          Gallery
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">All</h1>
          <div className="flex items-center justify-center gap-x-2.5 w-[1px] h-4 rotate-12 bg-gray-500"></div>
          <div className="flex items-center space-x-2.5">
            {showArrowLeft && (
              <ArrowBack
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("left")}
              />
            )}
            {showArrowRight && (
              <ArrowForward
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("right")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        {activeCarousel === "carouselOne" && <CarouselOne />}
        {activeCarousel === "carouselTwo" && <CarouselTwo />}
        {activeCarousel === "carouselThree" && <CarouselThree />}
        {activeCarousel === "carouselFour" && <CarouselFour />}
        {activeCarousel === "carouselFive" && <CarouselFive />}
        {activeCarousel === "carouselSix" && <CarouselSix />}
        {activeCarousel === "fishCarouselOne" && <FishCarouselOne />}
        {activeCarousel === "otherCarouselOne" && <OtherCarouselOne />}
        {activeCarousel === "otherCarouselTwo" && <OtherCarouselTwo />}
      </div>
    </div>
  );
};

export default ImagesAll;

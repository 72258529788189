import React, { useState } from "react";

import Button from "@mui/joy/Button";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";

import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/joy/IconButton";

export function Navbar() {
  const [open, setOpen] = useState(false);
  const [showGalleryOptions, setShowGalleryOptions] = useState(false);

  const handleMouseEnter = () => {
    setShowGalleryOptions(true);
  };

  const handleMouseLeave = () => {
    setShowGalleryOptions(false);
  };

  const handleGalleryClick = () => {
    setShowGalleryOptions(!showGalleryOptions);
  };

  const handleOptionClick = () => {
    setShowGalleryOptions(false);
  };

  return (
    <header className="bg-black text-white flex items-center justify-between w-full px-4 md:px-8 md:py-2 z-100">
      <div className="flex items-center justify-center max-sm:p-2 max-md:p-2">
        <img
          src="/logo.png"
          alt="logo"
          width={40}
          height={40}
          title="Edo Opanovic logo"
        />

        <div className="flex items-center justify-center flex-col ml-4 font-light">
          <p className="text-2xl">Fine Art of Dalmatia</p>
          <p className="text-lg uppercase">Digital Art</p>
        </div>
      </div>

      {/* Main Navbar */}
      <nav className="lg:flex items-center justify-center hidden">
        <a href="/">
          <Button
            size="lg"
            variant="plain"
            sx={{
              height: "2.5rem",
              fontSize: "26px",
              fontWeight: "300",
              fontFamily: "inherit",
              color: "white",
              ":hover": {
                backgroundColor: "inherit",
                color: "#F33A6A",
                transition: "all 0.3s ease",
              },
            }}
            className="hover:bg-inherit "
          >
            Home
          </Button>
        </a>

        <div className="relative hidden lg:flex items-center p-0">
          <Button
            size="lg"
            variant="plain"
            sx={{
              height: "40px",
              padding: "16px",
              fontSize: "26px",
              fontWeight: "300",
              fontFamily: "inherit",
              color: "white",
              ":hover": {
                backgroundColor: "inherit",
                color: "#F33A6A",
                transition: "all 0.3s ease",
              },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="hover:bg-inherit "
          >
            Gallery
          </Button>

          {showGalleryOptions && (
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="flex flex-col items-start absolute top-full left-0  bg-white p-1 max-h-[300px] text-black z-50"
            >
              <a href="/gallery/wooden-boats">
                <Button
                  size="lg"
                  variant="plain"
                  sx={{
                    height: "40px",
                    padding: "8px",
                    textWrap: "nowrap",
                    fontSize: "18px",
                    fontWeight: "300",
                    fontFamily: "inherit",
                    color: "black",
                  }}
                  onClick={handleOptionClick}
                >
                  Wooden Boats
                </Button>
              </a>

              <a href="/gallery/fish">
                <Button
                  size="lg"
                  variant="plain"
                  sx={{
                    height: "40px",
                    padding: "8px",
                    textWrap: "nowrap",
                    fontSize: "18px",
                    fontWeight: "300",
                    fontFamily: "inherit",
                    color: "black",
                  }}
                  onClick={handleOptionClick}
                >
                  Fish
                </Button>
              </a>

              <a href="/gallery/other">
                <Button
                  size="lg"
                  variant="plain"
                  sx={{
                    height: "40px",
                    padding: "8px",
                    textWrap: "nowrap",
                    fontSize: "18px",
                    fontWeight: "300",
                    fontFamily: "inherit",
                    color: "black",
                  }}
                  onClick={handleOptionClick}
                >
                  Other
                </Button>
              </a>

              <a href="/gallery/all">
                <Button
                  size="lg"
                  variant="plain"
                  sx={{
                    height: "40px",
                    padding: "8px",
                    textWrap: "nowrap",
                    fontSize: "18px",
                    fontWeight: "300",
                    fontFamily: "inherit",
                    color: "black",
                  }}
                  onClick={handleOptionClick}
                >
                  All
                </Button>
              </a>
            </div>
          )}
        </div>

        <a href="/blog">
          <Button
            size="lg"
            variant="plain"
            sx={{
              height: "40px",
              padding: "16px",
              fontSize: "26px",
              fontWeight: "300",
              fontFamily: "inherit",
              color: "white",
              ":hover": {
                backgroundColor: "inherit",
                color: "#F33A6A",
                transition: "all 0.3s ease",
              },
            }}
            className="hover:bg-inherit "
          >
            Blog
          </Button>
        </a>

        <a href="/contact">
          <Button
            size="lg"
            variant="plain"
            sx={{
              height: "40px",
              padding: "16px",
              fontSize: "26px",
              fontWeight: "300",
              fontFamily: "inherit",
              color: "white",
              ":hover": {
                backgroundColor: "inherit",
                color: "#F33A6A",
                transition: "all 0.3s ease",
              },
            }}
            className="hover:bg-inherit "
          >
            Contact
          </Button>
        </a>
      </nav>

      {/* Mobile Menu Icon */}
      <div className="lg:hidden ml-auto">
        <IconButton
          variant="plain"
          sx={{
            color: "white",
          }}
          onClick={() => setOpen(true)}
        >
          <Menu />
        </IconButton>
        <Drawer open={open} onClose={() => setOpen(false)} size="sm">
          <div className="md:flex flex flex-col items-center justify-center gap-4 p-0">
            <ModalClose id="close-icon" onClick={() => setOpen(false)} />

            <div className="flex items-center justify-center flex-col mt-10 gap-4 text-2xl font-light">
              <a href="/">
                <Button size="navigation" variant="navigation">
                  Home
                </Button>
              </a>

              <div className="relative">
                <Button
                  size="navigation"
                  variant="navigation"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Gallery
                </Button>

                {showGalleryOptions && (
                  <div
                    className="flex flex-col items-start absolute top-full -left-5 z-10 bg-black p-1 max-h-[180px] rounded-sm text-white"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a href="/gallery/wooden-boats">
                      <Button
                        size="lg"
                        variant="plain"
                        sx={{
                          height: "40px",
                          padding: "8px",
                          textWrap: "nowrap",
                          fontSize: "18px",
                          fontWeight: "300",
                          fontFamily: "inherit",
                          color: "white",
                        }}
                        onClick={handleOptionClick}
                      >
                        Wooden Boats
                      </Button>
                    </a>

                    <a href="/gallery/fish">
                      <Button
                        size="lg"
                        variant="plain"
                        sx={{
                          height: "40px",
                          padding: "8px",
                          textWrap: "nowrap",
                          fontSize: "18px",
                          fontWeight: "300",
                          fontFamily: "inherit",
                          color: "white",
                        }}
                        onClick={handleOptionClick}
                      >
                        Fish
                      </Button>
                    </a>

                    <a href="/gallery/other">
                      <Button
                        size="lg"
                        variant="plain"
                        sx={{
                          height: "40px",
                          padding: "8px",
                          textWrap: "nowrap",
                          fontSize: "18px",
                          fontWeight: "300",
                          fontFamily: "inherit",
                          color: "white",
                        }}
                        onClick={handleOptionClick}
                      >
                        Other
                      </Button>
                    </a>

                    <a href="/gallery/all">
                      <Button
                        size="lg"
                        variant="plain"
                        sx={{
                          height: "40px",
                          padding: "8px",
                          textWrap: "nowrap",
                          fontSize: "18px",
                          fontWeight: "300",
                          fontFamily: "inherit",
                          color: "white",
                        }}
                        onClick={handleOptionClick}
                      >
                        All
                      </Button>
                    </a>
                  </div>
                )}
              </div>

              <a href="/blog">
                <Button size="navigation" variant="navigation">
                  Blog
                </Button>
              </a>

              <a href="/contact">
                <Button size="navigation" variant="navigation">
                  Contact
                </Button>
              </a>
            </div>
          </div>
        </Drawer>
      </div>
    </header>
  );
}

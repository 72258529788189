import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Navbar } from "../../components/Navbar";

import OtherCarouselOne from "./components/otherOne";
import OtherCarouselTwo from "./components/otherTwo";

const OtherImages = () => {
  const [activeCarousel, setActiveCarousel] = useState("otherCarouselOne");
  const showArrowLeft = Boolean(activeCarousel !== "otherCarouselOne");
  const showArrowRight = Boolean(activeCarousel !== "otherCarouselTwo");

  const handleCarouselChange = (direction) => {
    setActiveCarousel((prevActive) => {
      const carouselMap = {
        otherCarouselOne:
          direction === "right" ? "otherCarouselTwo" : "otherCarouselOne",
        otherCarouselTwo:
          direction === "left" ? "otherCarouselOne" : "OtherCarouselTwo",
      };
      return carouselMap[prevActive];
    });
  };

  return (
    <div className="m-0 w-full z-20">
      <Helmet>
        <title>Edo Opanovic All Other Gallery</title>
        <meta
          name="description"
          content="Discover an eclectic array of Edo Opanovic's other works in this captivating gallery, showcasing a diverse range of artistic expressions and inspirations."
        />
        <meta
          name="keywords"
          content="
          stone house, old stone house, traditional stone house, stone wall, traditional stone wall, old stone wall, olive tree, olive grove, fish net, stone steps, traditional stone steps, wooden window, traditional wooden window, dalmatia, the adriatic sea, Mediterranean, old wooden barrel, wooden barrel, house by the sea, old mediterraen stone houses, traditional dalmatian stone house 
        "
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://fine-art-of-dalmatia.com/gallery/other"
        />
      </Helmet>
      <Navbar />

      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565] z-90">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          Gallery
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">Other</h1>
          <div className="flex items-center justify-center gap-x-2.5 w-[1px] h-4 rotate-12 bg-gray-500"></div>
          <div className="flex items-center space-x-2.5">
            {showArrowLeft && (
              <ArrowBack
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("left")}
              />
            )}
            {showArrowRight && (
              <ArrowForward
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("right")}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        {activeCarousel === "otherCarouselOne" && <OtherCarouselOne />}
        {activeCarousel === "otherCarouselTwo" && <OtherCarouselTwo />}
      </div>
    </div>
  );
};

export default OtherImages;

import React from "react";
import { Helmet } from "react-helmet-async";

import { Navbar } from "../../components/Navbar";

import BlogHero from "../../components/BlogHero";
import Leut from "../../components/Leut";
import Pasara from "../../components/Pasara";
import Batana from "../../components/Batana";
import Gajeta from "../../components/Gajeta";

const BlogPage = () => {
  return (
    <div className="relative bg-black text-white">
      <Helmet>
        <title>Edo Opanovic Blog</title>
        <meta
          name="description"
          content="Explore the captivating world of Edo Opanovic through insightful articles, engaging narratives, and thought-provoking discussions. Dive into topics ranging from technology to culture, and discover new perspectives on contemporary issues. Join the journey today!"
        />
        <meta
          name="keywords"
          content="Edo Opanovic, blog, leut, batana, gajeta, dalmacija, vizure, wooden-boats, traditional-boats"
        />
        <link
          rel="canonical"
          href="https://www.fine-art-of-dalmatia.com/blog"
        />
      </Helmet>
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
        <div className="flex items-center justify-between mx-0 h-[45px] bg-[#656565]">
          <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
            Blog
          </h1>
        </div>
      </div>
      <div className="mt-[15px]">
        <BlogHero />
        <Leut />
        <Pasara />
        <Batana />
        <Gajeta />
      </div>
    </div>
  );
};

export default BlogPage;

import React from "react";
import { Helmet } from "react-helmet-async";

import { Navbar } from "../../components/Navbar";

import FishCarouselOne from "./components/fishOne";

const FishImages = () => {
  return (
    <div className="m-0 w-full">
      <Helmet>
        <title>Gallery of Fish by Edo Opanovic</title>
        <meta
          name="description"
          content="Explore Edo Opanovic's Fish Gallery. Dive into the collection of fish crafted by Edo Opanovic"
        />
        <meta
          name="keywords"
          content="sardine, white fish, blue fish, tuna, smoked fish, crab, shell, marine wildlife, fishing
        "
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://fine-art-of-dalmatia.com/gallery/fish"
        />
      </Helmet>
      <Navbar />

      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565]">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          Gallery
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">Fish</h1>
        </div>
      </div>

      <FishCarouselOne />
    </div>
  );
};

export default FishImages;

import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Navbar } from "../../components/Navbar";

import CarouselOne from "./components/boatsOne";
import CarouselTwo from "./components/boatsTwo";
import CarouselThree from "./components/boatsThree";
import CarouselFour from "./components/boatsFour";
import CarouselFive from "./components/boatsFive";
import CarouselSix from "./components/boatsSix";

const WoodenBoats = () => {
  const [activeCarousel, setActiveCarousel] = useState("carouselOne");
  const showArrowLeft = Boolean(activeCarousel !== "carouselOne");
  const showArrowRight = Boolean(activeCarousel !== "carouselSix");

  const handleCarouselChange = (direction) => {
    setActiveCarousel((prevActive) => {
      const carouselMap = {
        carouselOne: direction === "right" ? "carouselTwo" : "carouselOne",
        carouselTwo: direction === "left" ? "carouselOne" : "carouselThree",
        carouselThree: direction === "left" ? "carouselTwo" : "carouselFour",
        carouselFour: direction === "left" ? "carouselThree" : "carouselFive",
        carouselFive: direction === "left" ? "carouselFour" : "carouselSix",
        carouselSix: direction === "left" ? "carouselFive" : "carouselSix",
      };
      return carouselMap[prevActive];
    });
  };

  return (
    <div className="m-0 w-full">
      <Helmet>
        <title>Gallery of Wooden Boats by Edo Opanovic</title>
        <meta
          name="description"
          content="Explore the Wooden Boats Gallery by Edo Opanovic. Dive into the collection of wooden boats crafted by Edo Opanovic."
        />
        <meta
          name="keywords"
          content="digital art, art gallery, fine art, modern art, Contemporary-Traditional Art, wooden boat, traditional wooden boat, old wooden boat, oar, sail, stern, hull, deck, rudder, fish net, shore, sailor, northern wind, southern wind, regatta, traditional regatta, latin sail, dalmatia, the adriatic sea, Mediterranean, southern Europe, port, traditional boat building, traditional shipbuilding, small wooden boat, handmade wooden boat, main sail, mast, knot, anchor, buoy"
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://fine-art-of-dalmatia.com/gallery/wooden-boats"
        />
      </Helmet>
      <Navbar />
      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565]">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          Gallery of Wooden Boats
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">Wooden Boats</h1>
          <div className="flex items-center justify-center gap-x-2.5 w-[1px] h-4 rotate-12 bg-gray-500"></div>
          <div className="flex items-center space-x-2.5">
            {showArrowLeft && (
              <ArrowBack
                sx={{
                  color: "white",
                }}
                className="cursor-pointer font-light"
                onClick={() => handleCarouselChange("left")}
              />
            )}
            {showArrowRight && (
              <ArrowForward
                className="cursor-pointer w-10 h-10"
                onClick={() => handleCarouselChange("right")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col">
        {activeCarousel === "carouselOne" && <CarouselOne />}
        {activeCarousel === "carouselTwo" && <CarouselTwo />}
        {activeCarousel === "carouselThree" && <CarouselThree />}
        {activeCarousel === "carouselFour" && <CarouselFour />}
        {activeCarousel === "carouselFive" && <CarouselFive />}
        {activeCarousel === "carouselSix" && <CarouselSix />}
      </div>
    </div>
  );
};

export default WoodenBoats;

import React from "react";
import { Helmet } from "react-helmet-async";

import { Navbar } from "./Navbar";

export default function BestWork() {
  return (
    <div className="mx-auto flex flex-col bg-black w-full h-full lg:w-screen text-white">
      <Helmet>
        <title>Portofolio of Dalmatia - Edo Opanović's best work</title>
        <meta
          name="description"
          content="Check out the best works of photographer Edo Opanović, including beautiful photos of wooden ships like the Pasara, as well as other inspiring motifs."
        />
        <link rel="canonical" href="https://www.fine-art-of-dalmatia.com/" />
      </Helmet>

      <Navbar />
      <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
        <img
          src="/bestWork/bestWork1.webp"
          alt="Wooden Boat Photography"
          width={430}
          height={350}
          className="object-cover pt-2 hidden lg:block mr-0 ml-20"
          title="Portofolio of Dalmatia Wooden Boat Photography"
        />
        <div className="flex flex-col items-center justify-center text-2xl text-white lg:text-4xl mb-10 lg:mb-0 mr-0 lg:mr-20">
          <div className="flex items-center justify-center flex-col">
            <h1 className="uppercase font-light mt-9">Some of my best work</h1>
            <img
              src="/traka.png"
              alt="traka"
              width={350}
              height={100}
              className="flex items-center justify-center mt-2 lg:mb-4"
              title="Ukrasna traka"
              loading="eager"
            />
          </div>
          <div className="flex items-center flex-col justify-center space-y-3">
            <div className="flex flex-col items-center justify-center space-y-3 lg:flex-row gap-x-0 lg:gap-x-3">
              <img
                src="/10.webp"
                alt="Pasara"
                width={280}
                height={500}
                className="h-[300px] lg:h-[200px] mt-4 lg:hidden"
                title="Pasara"
              />
              <img
                src="/bestWork/bestWork2.webp"
                alt="Riba"
                width={300}
                height={500}
                className="h-[200px]"
                title="Riba"
              />
              <img
                src="/bestWork/bestWork3.webp"
                alt="Bonaca"
                width={200}
                height={200}
                className="h-[200px]"
                title="Bonaca"
              />
              <img
                src="/bestWork/bestWork4.webp"
                alt="latinsko idro"
                width={300}
                height={500}
                className="h-[200px]"
                title="latinsko idro"
              />
            </div>
            <div className="flex flex-col items-center justify-center space-y-3 lg:space-y-0 lg:flex-row gap-x-3">
              <img
                src="/bestWork/bestWork5.webp"
                alt="na škveru"
                width={300}
                height={500}
                className="h-[200px]"
                title="na škveru"
              />
              <img
                src="/bestWork/bestWork6.webp"
                alt="na valu"
                width={200}
                height={500}
                className="h-[200px]"
                title="na valu"
              />
              <img
                src="/bestWork/bestWork7.webp"
                alt="falkuša - boats"
                width={300}
                height={500}
                className="h-[200px]"
                title="falkuša - boats"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
